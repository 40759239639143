<template>
    <div>
        <div class="l-inline l-center-v l-padded l-spread">
            <div class="list-header__title">
                <slot name="title">
                    List Header
                </slot>
            </div>
            <slot name="accessory" />
        </div>
        <div>
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListHeader',
}
</script>

<style lang="scss" scoped>
.list-header__title {
    font-weight: 300;
    font-size: $fs-base;
    font-size: 16px;
}
</style>
