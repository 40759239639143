<template>
    <div class="search-field">
        <SearchIcon width="15" height="15" class="search-field__icon" />

        <input
            type="search"
            class="search-field__input l-full-width"
            :placeholder="$t('search')"
            :value="value"
            autofocus="true"
            @input="handleInput"
        />
    </div>
</template>

<script>
import { debounce } from 'lodash'

import SearchIcon from './icons/SearchIcon'

export default {
    name: 'SearchField',
    components: {
        SearchIcon,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    methods: {
        handleInput: debounce(function(event) {
            this.$emit('input', event.target.value)
        }, 250),
    },
}
</script>

<i18n>
{
    "en": {
        "search": "Search..."
    },
    "de": {
        "search": "Suchen..."
    },
    "fr": {
        "search": "Rechercher..."
    },
    "it": {
        "search": "Ricerca..."
    }
}
</i18n>

<style lang="scss" scoped>
.search-field {
    display: flex;
    align-items: center;
    border-radius: $br-input;
    position: relative;
}

.search-field__icon {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    opacity: 0.5;
}

.search-field__input {
    -webkit-appearance: none;
    border-radius: $br-input;
    border: none;
    padding: 0.5rem;
    text-indent: 1.5rem;
    font-size: $fs-input;
    background: $background-input;
    transition: box-shadow 0.15s;

    &:focus {
        box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.2);
        outline: 0;
    }
}
</style>
